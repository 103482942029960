import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useUserAuth } from '../../contexts/UserAuthContext';
import './Navbar.css'

const Navbar = () => {
  const { logOut } = useUserAuth();

  const handleLogout = async () => {
    try {
        await logOut();
    } catch (error) {
        console.log(error.message);
    }
}

  return (
    <nav>
        <Link to="/">
          <img className='text-logo' src='/images/logo.png' />
        </Link>
        <ul>
            <NavLink exact="true" to="/" className='navbar-item' activeclassname='active'>Dashboard</NavLink>
            <NavLink to="/posts" className='navbar-item' activeclassname='active'>Posts</NavLink>
            <NavLink to="/users" className='navbar-item' activeclassname='active'>Users</NavLink>
        </ul>
        <button onClick={() => handleLogout()}>Log Out</button>
    </nav>
  )
}

export default Navbar