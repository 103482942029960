import React, { createContext, useContext, useEffect, useState } from 'react'
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../firebase'

const userAuthContext = createContext();

export function useAuth() {
    return useContext(userAuthContext);
}

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(true);

    function signIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logOut() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        })

        return unsub;
    }, []);

    return (<userAuthContext.Provider value={{signIn, logOut, user}}>{!loading && children}</userAuthContext.Provider>)
}

export function useUserAuth() {
    return useContext(userAuthContext);
}