import React from 'react'
import './Dropdown.css'

const Dropdown = ({ value, options, onChange }) => {
  return (
    <label>
        <select value={value} onChange={onChange}>
        {options.map((option) => (
            <option value={option.value} key={option.label}>{option.label}</option>
        ))}
        </select>
    </label>
  )
}

export default Dropdown