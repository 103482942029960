import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useUserAuth } from '../../contexts/UserAuthContext';
import './Users.css'

const Users = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useUserAuth();
  
  const getFetchedData = async () => 
  {
    setLoading(true);
    setLoading(false);
  };

  useEffect(()=> {
    getFetchedData();
  }, [])

  return (
    <main className='users-wrapper'>
        <Navbar />
        <div className='users-container'>
            
        </div>
    </main>
  )
}

export default Users