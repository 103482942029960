import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Dropdown from '../../../components/Dropdown/Dropdown'
import { FaPaperPlane, FaLink, FaTrash, FaEdit, FaUser, FaImage } from 'react-icons/fa'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import './NewPost.css'

const NewPost = () => {
    const [sections, setSections] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [sectionVisible, setSectionVisible] = useState(false);
    const [isUpdating, setIsUpdating] = useState('');
    const [newSectionTitle, setNewSectionTitle] = useState('');
    const [newSectionID, setNewSectionID] = useState('');
    const [newSectionAuthor, setNewSectionAuthor] = useState('');
    const [newSectionImage, setNewSectionImage] = useState('');
    const [newSectionBody, setNewSectionBody] = useState('');
    const [date, setDate] = useState('');
    const [activePost, setActivePost] = useState({});
    const [isRetrieving, setIsRetrieving] = useState(true);
    const { id } = useParams();
    let navigate = useNavigate();
    const title = useRef(null);
    const summary = useRef(null);
    const postid = useRef(null);
    const dateselect = useRef(null);
    const headerimg = useRef(null);

    const handleDateChange = (event) => setDate(event.target.value);
    const handleVisibilityChange = (event) => setVisibility(event.target.value);
    const handleTitle = (event) => setNewSectionTitle(event.target.value);
    const handleAuthor = (event) => setNewSectionAuthor(event.target.value);
    const handleSectionImage = (event) => setNewSectionImage(event.target.value);
    const handleBody = (event) => setNewSectionBody(event.target.value);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const newSections = Array.from(sections);
        const [reorderedItem] = newSections.splice(result.source.index, 1);
        newSections.splice(result.destination.index, 0, reorderedItem);
        setSections(newSections);
    }

    function editSection(id) {
        const index = sections.findIndex(el => el.id == id);
        if(index != -1) 
        {
            const section = sections[index];
            setNewSectionTitle(section.title);
            setNewSectionID(section.id);
            setNewSectionAuthor(section.author);
            setNewSectionImage(section.image);
            setNewSectionBody(section.body);
            setDate(section.date);
            setIsUpdating(true);
            setSectionVisible(true);
        }
    }

    function removeSection(id) {
        const index = sections.findIndex(el => el.id == id);
        if(index != -1) 
        {
            const newSections = Array.from(sections);
            newSections.splice(index, 1);
            setSections(newSections);
        }
    }
    
    function toggleSection() {
        if (isUpdating) setIsUpdating(false);
        setNewSectionTitle('');
        setNewSectionID('');
        setNewSectionAuthor('');
        setNewSectionImage('');
        setNewSectionBody('');
        setSectionVisible(!sectionVisible);
    }

    function addSection() {
        const newSections = Array.from(sections);
        newSections.push(
        {
            "id": newSectionTitle,
            "title": newSectionTitle,
            "author": newSectionAuthor,
            "image": newSectionImage,
            "body": newSectionBody
        });
        setNewSectionTitle('');
        setNewSectionID('');
        setNewSectionAuthor('');
        setNewSectionImage('');
        setNewSectionBody('');
        setSections(newSections);
        setSectionVisible(!sectionVisible);
    }

    function updateSection() {
        const index = sections.findIndex(el => el.id == newSectionID);
        if(index != -1) 
        {
            const newSections = Array.from(sections);
            const section = newSections[index];
            section.title = newSectionTitle;
            section.id = newSectionID;
            section.author = newSectionAuthor;
            section.image = newSectionImage;
            section.body = newSectionBody;
            setSections(newSections);
            setNewSectionTitle('');
            setNewSectionID('');
            setNewSectionAuthor('');
            setNewSectionImage('');
            setNewSectionBody('');
            setSectionVisible(!sectionVisible);
        }
    }

    async function createPost() {
        await fetch("https://realpixelstudios.herokuapp.com/posts",{
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({"id": postid.current.value, "title": title.current.value, "headerimg": headerimg.current.value, "summary": summary.current.value, "visible": false, "sections": sections, "date": date})
        }).then(res => res.json()).then(() => navigate('/posts'));
    }

    async function updatePost() {
        await fetch(`https://realpixelstudios.herokuapp.com/posts/${activePost._id}`,{
            method: 'PATCH',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({"id": postid.current.value, "title": title.current.value, "headerimg": headerimg.current.value, "summary": summary.current.value, "visible": visibility, "sections": sections, "date": date})
        }).then(res => res.json()).then(() => navigate('/posts'));
    }

    const updatePage = async () => 
    {
        setIsRetrieving(true);
        await fetch("https://realpixelstudios.herokuapp.com/posts",{headers: { 'Authorization': process.env.REACT_APP_AUTH_TOKEN }}).then(res => res.json()).then((result) => {
            const index = result.findIndex(el => el.id == id);
            if(index != -1) 
            {
                const post = result[index];
                setActivePost(post);
                setSections(post.sections);
                setVisibility(post.visible);

                if (post.date != null) {
                    const date = new Date(post.date).toISOString()?.split('T')[0];
                    if (date != null) {
                        setDate(date);
                    }
                }
                
                setIsRetrieving(false);
            } else {
                setIsRetrieving(false);
            }
        });
    };

    useEffect(()=> {
        updatePage();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const editing = id != undefined;
    return (
        <main className='new-post-main'>
            <Navbar />
            {
                isRetrieving ? null :
                <div className={sectionVisible ? 'new-post-wrapper hide' : 'new-post-wrapper'}>
                    <h1 className='meta-title'>Post Details</h1>
                    <div className='meta-data'>
                        <input type='text' placeholder='Post Title' defaultValue={editing ? activePost.title : null} ref={title} />
                        <FaPaperPlane className='icon' />
                        <input type='text' placeholder='Post ID' defaultValue={editing ? activePost.id : null} ref={postid} />
                        <FaLink className='icon' />
                        <input type='date' placeholder='Post Date' value={date} onChange={handleDateChange} ref={dateselect} />
                        <Dropdown
                            options={[
                                { label: 'Live', value: true },
                                { label: 'Hidden', value: false },
                            ]}
                            value={visibility}
                            onChange={handleVisibilityChange}
                        />
                    </div>
                    <div className='content-data'>
                        <FaImage className='headerimg-icon' />
                        <input type='text' placeholder='Post Header Image' defaultValue={editing ? activePost.headerimg : null} ref={headerimg} />
                        <textarea type='text' ref={summary} placeholder='Give a brief summary of the post. This will display in the posts header.' rows="4" cols="111" defaultValue={editing ? activePost.summary : null} />
                    </div>
                    <div className='sections-header'>
                        <h1 className='sections-title'>Sections</h1>
                        <button onClick={toggleSection}>Add Section</button>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='sections-data'>
                            {(provided) => (
                                <div className='sections-data' {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        sections.map((section, index) => {
                                            return(
                                                <Draggable key={section.id} draggableId={section.id} index={index}>
                                                    {(provided) => (
                                                        <div className='section' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <h1>{section.title}</h1>
                                                            <p>{section.author}</p>
                                                            <div className='section-actions'>
                                                                <FaEdit className='section-icon edit' onClick={()=>{editSection(section.id)}} />
                                                                <FaTrash className='section-icon trash' onClick={()=>{removeSection(section.id)}} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className='submit-section'>
                        <button disabled={sections.length == 0} onClick={editing ? updatePost : createPost}>{editing ? "Update Post" : "Create Post"}</button>
                    </div>
                </div>
            }
            
            <div className={sectionVisible ? 'new-section-wrapper' : 'new-section-wrapper hide'}>
                <div className='new-section-header'> 
                    <input type='text' placeholder='Section Title' onChange={handleTitle} value={newSectionTitle}/>
                    <FaPaperPlane className='new-section-icon' />
                    <input type='text' placeholder='Author Name' onChange={handleAuthor} value={newSectionAuthor} />
                    <FaUser className='new-section-icon' />
                    <input type='text' placeholder='Section Image' onChange={handleSectionImage} value={newSectionImage} />
                    <FaImage className='new-section-icon' />
                </div>

                <div className='new-section-body'>
                    <textarea type='text' placeholder='HTML Markdown Post (Use refrence to side)' rows="35" cols="70" onChange={handleBody} value={newSectionBody} />
                    <div className='new-section-helper'>
                        <h1>Text Formatting:</h1>
                        <p>
                            &lt;strong&gt;this is bold&lt;/strong&gt;
                            <br />
                            &lt;i&gt;this is italic&lt;/i&gt;
                        </p>
                        <h1>Links:</h1>
                        <p>&lt;a href=&quot;https://website.com/&quot; target=&quot;_blank&quot;&gt;Link Text&lt;/a&gt;</p>
                        <h1>Images:</h1>
                        <p>&lt;img src=&quot;https://website.com/image.jpg&quot;&gt;</p>
                        <h1>Videos:</h1>
                        <p>&lt;video src=&quot;https://website.com/image.jpg&quot;&gt;&lt;/video&gt;</p>
                        <h1>Image Gallery:</h1>
                        <p>
                            &lt;div className=&quot;gallery&quot;&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img src=&quot;https://website.com/image.jpg&quot;&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img src=&quot;https://website.com/image.jpg&quot;&gt;
                            <br />
                            &lt;/div&gt;
                        </p>
                        <h1>Image Caption Gallery:</h1>
                        <p>
                            &lt;div className=&quot;caption-gallery&quot;&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&lt;div className=&quot;feature-container&quot;&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;img src=&quot;https://website.com/image.jpg&quot;&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h1&gt;Title&lt;/h1&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span&gt;Caption&lt;/span&gt;
                            <br />
                            &nbsp;&nbsp;&nbsp;&lt;/div&gt;
                            <br />
                            &lt;/div&gt;
                        </p>
                        <div className='new-section-actions'>
                            <button onClick={toggleSection}>Back To Post</button>
                            <button onClick={isUpdating ? updateSection : addSection}>{isUpdating ? "Update Section" : "Add Section"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NewPost