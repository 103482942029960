import React, { useEffect, useState } from 'react'
import { useUserAuth } from "../../contexts/UserAuthContext"
import Navbar from '../../components/Navbar/Navbar'
import Commit from '../../components/Github/Commits/Commits'
import Issues from '../../components/Github/Issues/Issues'
import './Dashboard.css'

const Dashboard = () => {
  const [users, setUsers] = useState({});
  const [commits, setCommits] = useState({});
  const [issues, setIssues] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useUserAuth();

  const getFetchedData = async () => 
  {
    setLoading(true);
    await fetch("https://api.github.com/repos/Four-Development/Four/commits?per_page=1000", {headers: { 'Authorization': process.env.REACT_APP_GITHUB_PAT }}).then(res => res.json()).then((result) => setCommits(result));
    await fetch("https://api.github.com/repos/Four-Development/Four/issues?per_page=1000", {headers: { 'Authorization': process.env.REACT_APP_GITHUB_PAT }}).then(res => res.json()).then((result) => {setIssues(result)});
    await fetch("https://api.github.com/repos/Four-Development/Four/collaborators", {headers: { 'Authorization': process.env.REACT_APP_GITHUB_PAT }}).then(res => res.json()).then((result) => {setUsers(result)});
    setLoading(false);
  };

  useEffect(()=> {
    getFetchedData();
  }, [setIssues, setCommits])

  return (
    <main className='dashboard-wrapper'>
      <Navbar />
      <div className='github-container'>
        <Commit commits={commits} isLoading={loading} />
        <Issues issues={issues} users={users} isLoading={loading} />
      </div>
    </main>
  )
}

export default Dashboard