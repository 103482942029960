import React, { useState } from 'react'
import Pagination from '../../Pagination/Pagination';
import './Issues.css'

const Issues = ({ issues, users, isLoading }) => {
    const [issuesCurrentPage, setIssuesCurrentPage] = useState(1);
    const [issuesPerPage, setIssuesPerPage] = useState(7);

    if (isLoading || !issues) {
        return <h2></h2>
    }

    const lastIssuesIndex = issuesCurrentPage * issuesPerPage;
    const firstIssuesIndex = lastIssuesIndex - issuesPerPage;
    const currentIssues = issues.slice(firstIssuesIndex, lastIssuesIndex);

    return (
        <div className='github-issues-wrapper'>
            <div className='github-issues-header'>
                <h1 className='title'>Issues ({issues.length})</h1>
                <Pagination array={issues} perPageNum={issuesPerPage} pageNumber={issuesCurrentPage} setPageFunc={setIssuesCurrentPage} />
            </div>
            <div className='github-issues-container'>
                {
                    currentIssues.map(issue => (
                        <a href={issue.html_url} target="_blank" key={issue.node_id}>
                            <div className='github-issue'>
                                <img src={issue.assignee == null ? "https://i.stack.imgur.com/frlIf.png" : issue.assignee.avatar_url}></img>
                                <div className='issue-info'>
                                    <div className='author-info'>
                                        <h1>{issue.title}</h1>
                                    </div>
                                    <div className='issue-labels'>
                                       {
                                        issue.labels.map(label => {
                                            return <div className='issue' style={{backgroundColor: '#' + label.color}}>{label.name}</div>
                                        })                         
                                       }
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default Issues