import React, { useState } from 'react'
import Pagination from '../../Pagination/Pagination';
import './Commits.css'

const Commit = ({commits, isLoading}) => {
    const [commitCurrentPage, setCommitCurrentPage] = useState(1);
    const [commitsPerPage, setCommitsPerPage] = useState(7);

    if (isLoading || !commits) {
        return <h2></h2>
    }

    const lastCommitIndex = commitCurrentPage * commitsPerPage;
    const firstCommitIndex = lastCommitIndex - commitsPerPage;
    const currentCommits = commits.slice(firstCommitIndex, lastCommitIndex);

    return (
        <div className='github-commits-wrapper'>
            <div className='github-commit-header'>
                <h1 className='title'>Past 100 Commits</h1>
                <Pagination array={commits} perPageNum={commitsPerPage} pageNumber={commitCurrentPage} setPageFunc={setCommitCurrentPage} />
            </div>
            <div className='github-commits-container'>
                {
                    currentCommits.map(commit => (
                        <a href={commit.html_url} target="_blank" key={commit.sha}>
                            <div className='github-commit'>
                                <img src={commit.committer.avatar_url}></img>
                                <div className='commit-info'>
                                <div className='author-info'>
                                    <h1>{commit.commit.author.name}</h1>
                                    <p>{new Date(commit.commit.author.date).toLocaleString()}</p>
                                </div>
                                <p>{commit.commit.message}</p>
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default Commit