import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../contexts/UserAuthContext';
import './Login.css';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { signIn } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setError('');
            await signIn(email, password);
            navigate("/");
        } catch (error) {
            setError("The information you have entered does not match our records.");
        }
    }

    return (
        <main className='login-wrapper'>
            <img src="/images/logo-big.png" />
            <div className='login-container'>
                <h1>Login</h1>
                { error != '' ? <div className='error'>{error}</div> : null }
                <form className='login-box' onSubmit={handleSubmit}>
                    <input type="text" placeholder='Email..' onChange={(e) => setEmail(e.target.value)} required></input>
                    <input type="password" placeholder='Password..' onChange={(e) => setPassword(e.target.value)} required></input>
                    <button>Sign In</button>
                </form>
            </div>
        </main>
    )
}

export default Login