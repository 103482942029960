import React from 'react'
import './Pagination.css'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'

const Pagination = ({array, perPageNum, pageNumber, setPageFunc}) => {
    const changePage = (value) => {
        if (value <= 0) value = 1;
        if (value > Math.ceil(array.length / perPageNum)) value = Math.ceil(array.length / perPageNum);
        setPageFunc(value);
    }

    return (
        <div className='pagination-container'>
            <BiArrowToLeft className='left-outer-icon' onClick={()=>changePage(1)}/>
            <FaArrowLeft className='left-icon' onClick={()=>changePage(pageNumber-1)}/>
            <input type='number' value={pageNumber} onChange={(e) => changePage(e.target.value)}></input>
            <FaArrowRight className='right-icon' onClick={()=>changePage(pageNumber+1)}/>
            <BiArrowToRight className='right-outer-icon' onClick={()=>changePage(Math.ceil(array.length / perPageNum))}/>
        </div>
    )
}

export default Pagination