import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './Posts.css'

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [requiresUpdate, setRequiresUpdate] = useState(true);

  function deletePost(id) {
    console.log(id);
    const index = posts.findIndex(el => el._id == id);
    if(index != -1) 
    {
      fetch(`https://realpixelstudios.herokuapp.com/posts/${id}`, {method: 'DELETE'}).then(res => res.json()).then(() => setRequiresUpdate(true));
    }
  }

  const getPosts = async () => 
  {
    setLoading(true);
    await fetch("https://realpixelstudios.herokuapp.com/posts",{headers: { 'Authorization': process.env.REACT_APP_AUTH_TOKEN }}).then(res => res.json()).then((result) => setPosts(result.reverse()));    
    setLoading(false);
  };

  useEffect(() => {
    if (requiresUpdate) { console.log('Updated!'); setRequiresUpdate(false); getPosts(); }
  });

  return (
    <main className='posts-main'>
        <Navbar />
        <div className='posts-wrapper'>
          <div className='posts-action-bar'>
            <h1>Our Posts</h1>
            <div className='posts-action-buttons'>
              <Link to='/posts/newpost' className='new-post-button'>New Post</Link>
            </div>
          </div>
          <div className='posts-container'>
            {
              isLoading ? null :
              posts.map(post => (
                <div className='post'>
                  <div className='post-info'>
                    <div className='post-header'>
                      <h1>{post.title}</h1>
                      <div className={post.visible ? "post-status live" : "post-status hidden"}>{post.visible ? "Live" : "Hidden"}</div>
                    </div>
                    <p>{post.summary}</p>
                  </div>
                  <div className='post-actions'>
                    <Link to={'/posts/editpost/' + post.id}><FaEdit className='post-action-icon edit'></FaEdit></Link>
                    <FaTrash className='post-action-icon trash' onClick={() => deletePost(post._id)}/>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
    </main>
  )
}

export default Posts