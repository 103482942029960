import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard'
import Users from './pages/Users/Users'
import Protected from './components/Protected/Protected'
import LoggedIn from './components/Protected/LoggedIn'
import { UserAuthContextProvider } from './contexts/UserAuthContext'
import './App.css';
import Posts from './pages/Posts/Posts';
import NewPost from './pages/Posts/NewPost/NewPost';

function App() { 
  return (
    <Router>
      <UserAuthContextProvider>
          <Routes>
            <Route exact path="/" element={ <Protected><Dashboard /></Protected> } />
            <Route path="/users" element={ <Protected><Users /></Protected> } />
            <Route path="/posts" element={ <Protected><Posts /></Protected> } />
            <Route path="/posts/newpost" element={ <Protected><NewPost /></Protected> } />
            <Route path="/posts/editpost/:id" element={ <Protected><NewPost/></Protected> } />
            <Route path="/login" element={ <LoggedIn><Login /></LoggedIn> } />
          </Routes>
      </UserAuthContextProvider>
    </Router>
  );
}

export default App;
